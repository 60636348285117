import React, { FC } from "react";

export interface JobBoxProps {
  url: string;
  title: string;
  lead: string;
  location: string;
  icon: string;
  iconAlt: string;
}

export const JobBox: FC<JobBoxProps> = ({
  url,
  title,
  lead,
  location,
  icon,
  iconAlt,
}) => (
  <a
    className="d-block l-push-bottom border-bottom border-bottom-reset-last l-with-gutter-bottom-mobile"
    href={url}
  >
    <div className="inline-table">
      <div
        className="inline-table-item v-align-top-tablet l-with-dbl-gutter-after-mobile"
        style={{ width: "80px" }}
      >
        <div className="image-object">
          <img alt={iconAlt} src={icon} />
        </div>
      </div>
      <div className="inline-table-item v-align-middle">
        <h2 className="text-1-5 color-secondary text-tight">{title}</h2>
        <div className="text-1 hidden-mobile" style={{ lineHeight: "1.25" }}>
          {lead}
        </div>
        <span className="color-secondary">LOCATION </span>
        <span>{location}</span>
      </div>
    </div>
  </a>
);
