import React, { FC } from "react";
import { graphql, PageProps } from "gatsby";
import { MainLayout } from "@/layouts/MainLayout";
import Helmet from "react-helmet";
import { SEO } from "@/components/basic/SEO";
import { TestimonialCarousel } from "@/components/TestimonialCarousel";
import { WhatWeDoBox } from "@/components/WhatWeDoBox";
import { BlogPostBox } from "@/components/BlogPostBox";
import { JobBox } from "@/components/JobBox";
import ReactMarkdown from "react-markdown";
import { awardsData } from "@/utils/awardsData";
import { HeroCarousel } from "@/components/HeroCarousel";
import { Link } from "@/components/basic/Link";
import { SiteFooterWrapper } from "@/components/basic/SiteFooterWrapper";

const Homepage: FC<PageProps<GatsbyTypes.Query>> = ({ data }) => {
  const {
    metaTitle,
    metaDescription,
    metaKeywords,
    heroBoxes,
    productBoxes,
  } = data.contentfulHomepage;
  const whatWeDoContent = data.allContentfulWhatWeDoBox;
  const newsContent = data.allContentfulNewsPost;
  const jobsContent = data.allContentfulJobListing;
  const keyNoContent = data.allContentfulKeyNumber;
  const customerLogoContent = data.allContentfulCustomerLogo;

  return (
    <MainLayout>
      <Helmet>
        <body className="ltr homepage" id="intro" />
      </Helmet>
      <SEO
        title={metaTitle}
        description={metaDescription.metaDescription}
        keywords={metaKeywords}
        isHomepage
      />
      <HeroCarousel />
      <div className="l-wrapper color-bg-negative clearfix l-dbl-push-bottom">
        <h6 className="text-2 section-heading color-primary align-center">
          Solutions we provide
        </h6>
        <div className="l-11-cols-tablet l-12-cols-desktop l-island clearfix l-bleed">
          {[...productBoxes]
            .sort((a, b) => a.order - b.order)
            .map(({ title, url, cover, body }) => (
              <div
                className="l-4-cols-desktop l-push-bottom l-dbl-push-bottom-tablet"
                key={title}
              >
                <a href={url} className="image-card-wrapper">
                  <div
                    className="image-card"
                    style={{ backgroundImage: `url("${cover.file.url}")` }}
                  ></div>
                  <div className="image-card-content color-bg-negative">
                    <h2 className="color-primary l-push-top">{title}</h2>
                    <ReactMarkdown allowDangerousHtml>
                      {body.body}
                    </ReactMarkdown>
                  </div>
                  <div className="color-bg-negative align-end">
                    <div className="color-primary">
                      <span className="d-inline-block">READ MORE</span>
                      <img
                        alt="Chevron icon"
                        className="d-inline-block"
                        src="/images/icons/chevron.svg"
                        style={{
                          verticalAlign: "middle",
                          marginTop: "-2.5px",
                          marginLeft: "5px",
                        }}
                        width="8"
                      />
                    </div>
                  </div>
                </a>
              </div>
            ))}
        </div>
        <div className="align-center l-push-top">
          <a
            className="button button-ghost-primary"
            href="/travel-industry-solutions"
          >
            SHOW MORE DEDICATED SOLUTIONS
          </a>
        </div>
      </div>
      <div className="what-we-do-boxes l-wrapper color-bg-negative clearfix l-dbl-push-bottom">
        <h6 className="text-2 section-heading color-primary align-center">
          What we do
        </h6>
        <div className="l-11-cols-tablet l-12-cols-desktop l-island clearfix l-bleed d-flex-box d-flex-box-wrap">
          {whatWeDoContent.edges.map(({ node }) => (
            <WhatWeDoBox
              title={node.title.title}
              icon={node.icon.file.url}
              url={node.link}
              key={node.title.title}
            />
          ))}
        </div>
      </div>
      <div className="testimonial-box l-dbl-push-bottom color-bg-light l-with-dbl-vertical-gutters-mobile">
        <div className="l-wrapper clearfix">
          <TestimonialCarousel reversedTestimonialsOrder={false} />
        </div>
      </div>
      <div className="l-wrapper testimonial color-bg-negative clearfix">
        <div className="clearfix l-dbl-push-bottom">
          <div className="clearfix">
            <h6 className="text-2 section-heading color-primary align-center l-push-bottom">
              Latest blog posts
            </h6>
            <div className="clearfix l-dbl-push-bottom">
              {newsContent.edges.map(({ node }) => (
                <div
                  key={node.title}
                  className="l-half-lg-mobile l-half-tablet l-one-fourth-desktop l-push-bottom"
                >
                  <BlogPostBox
                    url={`/blog/${node.url}`}
                    date={node.date}
                    title={node.title}
                    lead={node.lead}
                    coverImage={node.coverImage.file.url}
                    imageAlt={node.coverImage.title}
                    thumbnailImage={node.thumbnailImage.file.url}
                    isHomepage
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="align-center l-push-bottom">
          <a href="/blog" className="button button-ghost-primary">
            SHOW MORE BLOG POSTS
          </a>
        </div>
      </div>
      <div className="careers-section l-dbl-push-bottom">
        <div className="l-wrapper clearfix">
          <div className="l-island l-10-cols-tablet l-6-cols-desktop l-dbl-push-bottom centered-text-section centered-text-section-secondary">
            <h6 className="text-2 align-center section-heading color-secondary">
              Join Us
            </h6>
            <p>
              We’re always open to talk to anyone interested in what we do.
              There’s a multitude of positions you might be interested in. So
              have a look at our listings and even if they don’t fit your
              profile - Just call and say Hi. Maybe we can work something out
              together.
            </p>
          </div>
          <h6 className="text-2 color-secondary align-center l-push-bottom">
            Latest listings
          </h6>
          <div className="l-10-cols-tablet l-7-cols-desktop l-island">
            {jobsContent.edges.map(({ node }, index) => (
              <JobBox
                key={index}
                url={node.bambooLink}
                title={node.jobTitle}
                lead={node.jobLead}
                location={node.location}
                icon={node.icon?.file.url}
                iconAlt={node.icon?.title}
              />
            ))}
            <div className="align-center l-push-top l-push-bottom">
              <a className="button button-ghost-primary" href="/jobs">
                SHOW MORE LISTINGS
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="color-bg-light">
        <div className="l-wrapper feature-box">
          <h6 className="text-2 align-center color-primary">
            ANIXE in numbers
          </h6>
          <div className="clearfix">
            {keyNoContent.edges.map(({ node }) => (
              <div
                key={node.title}
                className="feature-single l-one-third-tablet"
              >
                <h5>{node.number}</h5>
                <h6>{node.title}</h6>
                {node.body && (
                  <ReactMarkdown allowDangerousHtml>
                    {node.body.body}
                  </ReactMarkdown>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="customers-section">
        <div className="l-10-cols-mobile l-island l-6-cols-tablet l-dbl-push-bottom">
          <h6 className="text-2 section-heading color-primary align-center l-push-bottom">
            Selected Customers
          </h6>
        </div>
        <div className="l-wrapper">
          <div className="l-12-cols-desktop clearfix l-island">
            {customerLogoContent.edges.map(({ node }) => (
              <div
                key={node.id}
                className="l-4-cols-mobile l-2-cols-tablet l-2-cols-desktop l-push-bottom"
              >
                <div className="image-object image-object-desaturate">
                  <img alt={node.logo.title} src={node.logo.file.url} />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="hero-image color-bg-light">
          <div className="l-wrapper clearfix">
            {heroBoxes.map(({ title, body }) => (
              <div className="l-4-cols-tablet hero-image-content" key={title}>
                <h6 className="title-text color-primary">{title}</h6>
                <ReactMarkdown allowDangerousHtml>{body.body}</ReactMarkdown>
              </div>
            ))}
          </div>
        </div>
        <div className="l-wrapper">
          <h6 className="text-2 section-heading color-primary align-center l-push-bottom">
            Awards
          </h6>
          <div className="l-12-cols-tablet l-12-cols-desktop clearfix l-island">
            {awardsData.map(({ title, url, imageUrl, imageAlt }) => (
              <div
                key={title}
                className="l-4-cols-mobile l-2-cols-tablet l-2-cols-desktop l-push-bottom"
              >
                <a className="image-object image-object-desaturate" href={url}>
                  <img alt={imageAlt} src={imageUrl} />
                </a>
                <h5 className="color-primary align-center text-box-height">
                  {title}
                </h5>
              </div>
            ))}
          </div>
        </div>
      </div>
      <SiteFooterWrapper>
        <Link
          classname="button button-quaternary-negative-dark button-estimate-project"
          url="/estimate-project"
          text="estimate project"
        />
        <Link
          classname="button button-primary button-request-demo"
          url="/demo-form"
          text="request a demo"
        />
      </SiteFooterWrapper>
    </MainLayout>
  );
};

export default Homepage;
export const query = graphql`
  {
    contentfulHomepage {
      metaTitle
      metaDescription {
        metaDescription
      }
      metaKeywords
      heroBoxes {
        title
        body {
          body
        }
      }
      productBoxes {
        order
        url
        cover {
          file {
            url
          }
        }
        title
        body {
          body
        }
      }
    }
    allContentfulWhatWeDoBox(sort: { fields: [order] }) {
      edges {
        node {
          icon {
            file {
              url
            }
          }
          title {
            title
          }
          link
        }
      }
    }
    allContentfulNewsPost(
      filter: { hideOnHomepage: { nin: true } }
      limit: 4
      sort: { fields: [date], order: DESC }
    ) {
      edges {
        node {
          date(formatString: "DD MMM YYYY", locale: "en-US")
          title
          hideOnHomepage
          url
          lead
          thumbnailImage {
            file {
              url
            }
          }
          coverImage {
            title
            file {
              url
            }
          }
        }
      }
    }
    allContentfulJobListing(
      limit: 3
      sort: { fields: [updatedAt], order: DESC }
    ) {
      edges {
        node {
          bambooLink
          jobLead
          jobTitle
          location
          icon {
            title
            file {
              url
            }
          }
        }
      }
    }
    allContentfulKeyNumber(sort: { fields: [order] }) {
      edges {
        node {
          number
          title
          body {
            body
          }
        }
      }
    }
    allContentfulCustomerLogo(sort: { fields: [order] }) {
      edges {
        node {
          id
          logo {
            file {
              url
            }
            title
          }
        }
      }
    }
  }
`;
