import React, { FC } from "react";
import { Carousel } from "@/components/basic/Carousel";
import { HeroSlide } from "@/components/HeroSlide";
import { graphql, useStaticQuery } from "gatsby";

export const HeroCarousel: FC = () => {
  const data: GatsbyTypes.Query = useStaticQuery(graphql`
    {
      allContentfulHeroCarouselSlide(sort: { fields: [order] }) {
        edges {
          node {
            heroPhoto {
              file {
                url
              }
            }
            heading
            bodyText {
              bodyText
            }
            linkText
            linkUrl
          }
        }
      }
    }
  `);

  const slides = [...data.allContentfulHeroCarouselSlide.edges];

  return (
    <div className="hero-carousel">
      <Carousel interval={5000}>
        {slides.map(({ node }, index) => (
          <HeroSlide
            index={index}
            key={node.heroPhoto.file.url}
            photoUrl={node.heroPhoto.file.url}
            heading={node.heading}
            bodyText={node.bodyText.bodyText}
            linkText={node.linkText}
            linkUrl={node.linkUrl}
          />
        ))}
      </Carousel>
    </div>
  );
};
