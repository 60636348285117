export const awardsData = [
  {
    title: "Travel Weekly Magellan Award",
    url: "/blog/and-the-travel-weekly-magellan-award-goes-to-anixe",
    imageUrl: "/images/awards/magellan.png",
    imageAlt: "Magellan Award badge",
  },
  {
    title: "Eastern European Business Elite Award",
    url: "/blog/european-business-elite-award-for-anixe",
    imageUrl: "/images/awards/cv.png",
    imageAlt: "Business Elite Award badge",
  },
  {
    title: "Deloitte Fast 50 Central Europe",
    url: "",
    imageUrl: "/images/awards/deloitte.png",
    imageAlt: "Deloitte Fast 50 Central Europe statuette",
  },
  {
    title: "Business Gazelle Award 2020",
    url: "/blog/anixe-business-gazelle-of-the-year-2020",
    imageUrl: "/images/awards/gasseles.png",
    imageAlt: "Business Gazelle Award badge",
  },
  {
    title: "Forbes Diamond Award 2019",
    url: "/blog/anixe-honoured-in-the-forbes-diamonds-2019",
    imageUrl: "/images/awards/diamond2019.png",
    imageAlt: "Forbes Diamond Certification badge",
  },
  {
    title: "Travel & Hospitality Award 2020",
    url: "/blog/anixe-the-travel-technology-provider-of-the-year-2020",
    imageUrl: "/images/awards/tha.png",
    imageAlt: "Travel & Hospitality Award badge",
  },
];
