import React, { FC } from "react";
import ReactMarkdown from "react-markdown";

export interface WhatWeDoProps {
  title: string;
  icon: string;
  url: string;
}

export const WhatWeDoBox: FC<WhatWeDoProps> = ({ icon, title, url }) => (
  <div className="what-we-do-box l-full-mobile l-half-lg-mobile l-4-cols-desktop d-flex-box flex-box-center-x">
    <a
      href={url}
      className="what-we-do-link d-flex-box d-flex-dir-column l-full-mobile"
    >
      <img alt="" src={icon} className="what-we-do-image" />
      <ReactMarkdown allowDangerousHtml>{title}</ReactMarkdown>
    </a>
  </div>
);
