import React, { FC } from "react";
import ReactMarkdown from "react-markdown";

export interface TestimonialProps {
  index: number;
  photoUrl: string;
  heading?: string;
  bodyText?: string;
  linkText?: string;
  linkUrl?: string;
}
export const HeroSlide: FC<TestimonialProps> = ({
  index,
  photoUrl,
  heading,
  bodyText,
  linkText,
  linkUrl,
}) => (
  <a href={linkText && linkUrl}>
    <div
      className="hero-slide"
      style={{
        backgroundImage: `url(${photoUrl})`,
      }}
    >
      <div className="hero-slide-body l-wrapper clearfix">
        <div className="l-11-cols-tablet l-10-cols-desktop l-island clearfix l-bleed">
          <div className="l-cols-12-desktop">
            {index === 0 && heading && (
              <h1 className="hero-slide-heading">{heading}</h1>
            )}
            {index > 0 && heading && (
              <span className="hero-slide-heading">{heading}</span>
            )}
            {bodyText && (
              <div className="body-text">
                <ReactMarkdown allowDangerousHtml={true}>
                  {bodyText}
                </ReactMarkdown>
              </div>
            )}
            {linkText && (
              <div className="button-wrapper">
                <a className="button button-ghost-negative" href={linkUrl}>
                  {linkText}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  </a>
);
